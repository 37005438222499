<template>
  <Icon
    :color="computedColor || props.color"
    mode="svg"
    :name="name"
    :size="size"
  />
</template>

<script lang="ts" setup>
import type { ColorsKey } from '~/types';

const appConfigStore = useAppConfigStore();

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  color: {
    type: [String, Array, Object] as PropType<
      string | string[] | Record<string, boolean> | ColorsKey
    >,
    default: 'primary',
  },
  size: {
    type: String,
    default: 24,
  },
});

const computedColor = computed(() => {
  if ((props.color as ColorsKey) in allColors[appConfigStore.colorMode]) {
    return allColors[appConfigStore.colorMode][props.color as ColorsKey];
  }

  return props.color;
});
</script>
